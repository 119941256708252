export const Levels = () => {
    return (<div class="grades">
    <div class="dropsreverted"></div>
    <div class="row">
        <div class="offset-lg-2 col-lg-3">
            <div class="grade">
                <h2>Pre-Jardin</h2>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="grade">
                <h2>Jardín</h2>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="grade">
                <h2>Transición</h2>
            </div>
        </div>
    </div>
</div>)
}